/* UI controls */

select {
  font-size: inherit;
  padding: 6px 10px;
  border-radius: 8px;
}

select:focus {
  box-shadow: 0px 0px 0px 3px rgb(155 203 255);
  outline: none;
}

/* Loader */

.timer {
  zoom: 0.5;
  width: 300px;
  height: 300px;
  margin: 40px auto;
  position: relative;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotation 5s infinite linear;
}

.outside {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fdfdfd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  background-color: #ebebeb;
  background-image: -webkit-linear-gradient(bottom, #ebebeb, #fdfdfd);
  background-image: linear-gradient(to top,#ebebeb, #fdfdfd);
}

.first-ring {
  width: 83%;
  height: 83%;
  border-radius: 100%;
  background-color: #e2e2e2;
  border: 1px solid #cfcfcf;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.first-ring .timer-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inside {
  width: 70%;
  height: 70%;
  background-color: #fafafa;
  border-radius: 100%;
  border: 1px solid #cfcfcf;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.knob {
  width: 95%;
  height: 95%;
  background-color: red;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 3;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fdfdfd;
  background-image: -webkit-linear-gradient(bottom, #fdfdfd, #ebebeb);
  background-image: linear-gradient(to top,#fdfdfd, #ebebeb);
}

.inside-knob {
  width: 85%;
  height: 85%;
  border-radius: 100%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ebebeb;
  background-image: -webkit-linear-gradient(bottom, #ebebeb, #fdfdfd);
  background-image: linear-gradient(to top,#ebebeb, #fdfdfd);
}

.arrow {
  width: 100%;
  height: 100%;
  position: relative;
}
.arrow:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  top: 5px;
  left: calc(50% - 10px);
  border-style: solid;
  border-width: 0 10px 13px 10px;
  border-color: transparent transparent #ff0015 transparent;
}
