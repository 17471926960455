select {
  font-size: inherit;
  border-radius: 8px;
  padding: 6px 10px;
}

select:focus {
  outline: none;
  box-shadow: 0 0 0 3px #9bcbff;
}

.timer {
  zoom: .5;
  width: 300px;
  height: 300px;
  margin: 40px auto;
  position: relative;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: 5s linear infinite rotation;
}

.outside {
  width: 100%;
  height: 100%;
  background-color: #ebebeb;
  background-image: linear-gradient(to top, #ebebeb, #fdfdfd);
  border-radius: 100%;
  position: relative;
  box-shadow: 0 0 20px #0003;
}

.first-ring {
  width: 83%;
  height: 83%;
  background-color: #e2e2e2;
  border: 1px solid #cfcfcf;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
  overflow: auto;
}

.first-ring .timer-lines {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.inside {
  width: 70%;
  height: 70%;
  background-color: #fafafa;
  border: 1px solid #cfcfcf;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
  overflow: auto;
}

.knob {
  width: 95%;
  height: 95%;
  z-index: 3;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, #fdfdfd, #ebebeb);
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
  overflow: auto;
  box-shadow: 0 0 10px #00000080;
}

.inside-knob {
  width: 85%;
  height: 85%;
  background-color: #ebebeb;
  background-image: linear-gradient(to top, #ebebeb, #fdfdfd);
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
  overflow: auto;
}

.arrow {
  width: 100%;
  height: 100%;
  position: relative;
}

.arrow:after {
  content: "";
  width: 0;
  height: 0;
  border: 10px solid #0000;
  border-top-width: 0;
  border-bottom: 13px solid #ff0015;
  position: absolute;
  top: 5px;
  left: calc(50% - 10px);
}

/*# sourceMappingURL=index.8920a618.css.map */
